import React, { Suspense, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Home
const Home = React.lazy(() => import("./components/pages/Home"));
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
// About Us
const About = React.lazy(() => import("./components/pages/About"));
// Portfolio
const Portfoliogrid = React.lazy(() => import("./components/pages/Portfoliogrid"));
const Portfoliogridleft = React.lazy(() => import("./components/pages/Portfoliogridleft"));
const Portfoliogridright = React.lazy(() => import("./components/pages/Portfoliogridright"));
const Portfoliodetails = React.lazy(() => import("./components/pages/Portfoliodetails"));
const Portfoliodetailsleft = React.lazy(() => import("./components/pages/Portfoliodetailsleft"));
const Portfoliodetailsright = React.lazy(() => import("./components/pages/Portfoliodetailsright"));
// Blog
const Bloggrid = React.lazy(() => import("./components/pages/Bloggrid"));
const Bloggridleft = React.lazy(() => import("./components/pages/Bloggridleft"));
const Bloggridright = React.lazy(() => import("./components/pages/Bloggridright"));
const Blogdetails = React.lazy(() => import("./components/pages/Blogdetails"));
const Blogdetailsleft = React.lazy(() => import("./components/pages/Blogdetailsleft"));
const Blogdetailsright = React.lazy(() => import("./components/pages/Blogdetailsright"));
// Service
const Service = React.lazy(() => import("./components/pages/Service"));
const Servicedetails = React.lazy(() => import("./components/pages/Servicedetails"));
// FAQ's
const Faqs = React.lazy(() => import("./components/pages/Faqs"));
// Gallery
const Gallery = React.lazy(() => import("./components/pages/Gallery"));
// Pages
const Typography = React.lazy(() => import("./components/pages/Typography"));
const Comingsoon = React.lazy(() => import("./components/pages/Comingsoon"));
// Contact Us
const Contact = React.lazy(() => import("./components/pages/Contact"));
// Error Page
const Errorpage = React.lazy(() => import("./components/pages/Errorpage"));


// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
})


function App() {
  return (
    <Router basename={"/"}>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <Preloader />
          <Switch>
            {/* Home */}
            <Route exact path="/" component={Home} />
          </Switch>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
